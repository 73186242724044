var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Novo Registro ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          find: "",
          showAppend: ""
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c("emc-button-icon", {
                  attrs: {
                    loading: _vm.loading,
                    icon: "mdi-refresh",
                    color: "success",
                    text: "Atualizar"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.getData(false)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.name) + " "),
                _c("small", [_c("br"), _vm._v(_vm._s(item.type.name))]),
                item.description
                  ? _c("small", [_c("br"), _vm._v(_vm._s(item.description))])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "justify-center",
                    staticStyle: { width: "120px" },
                    attrs: { color: _vm.getColor(item.status), dark: "" }
                  },
                  [
                    _vm._v(" " + _vm._s(item.status.name) + " "),
                    item.dynamic_allocation_status_id == 1
                      ? _c("v-progress-linear", {
                          attrs: {
                            color: "deep-purple accent-4",
                            indeterminate: "",
                            rounded: "",
                            height: "6"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.campuses",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [_vm._v(_vm._s(_vm.getCampuses(item)))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.campuses
                              .map(function(o) {
                                return o["name"]
                              })
                              .join(", ")
                          ) +
                          " "
                      )
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "item.optimization_rate",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-progress-linear",
                  {
                    attrs: {
                      height: "25",
                      color: "light-blue",
                      striped: "",
                      value: item.optimization_rate
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(parseFloat(item.optimization_rate)) + "%")
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                item.status.id == 6 && item.error_message
                  ? _c("emc-info-help", {
                      attrs: {
                        iconColor: "deep-orange",
                        icon: "mdi-alert",
                        title: "Erro..."
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.error_message.length > 300
                                        ? item.error_message.substring(0, 300) +
                                            "..."
                                        : item.error_message
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : _vm._e(),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "amber" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showGenerate(item)
                                        }
                                      }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-flash")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(" Processar Alocações ")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "green" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showSyncItem(item)
                                        }
                                      }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-sync")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(" Sincronizar Resultados ")])]
                ),
                _c("emc-button-icon", {
                  attrs: {
                    disabled: _vm.enableIcon(item),
                    icon: "mdi-monitor-eye",
                    text: "Visualizar Resultado",
                    href: "dynamic-allocation-results?id=" + item.id,
                    iconSmall: ""
                  }
                }),
                _c("emc-table-icon-download-pdf", {
                  attrs: {
                    disabled: _vm.enableIcon(item),
                    toolTip: "Imprimir Resultado",
                    type: "allocation-result",
                    item: item
                  }
                }),
                _c("s-icon-report-download", {
                  attrs: {
                    disabled: _vm.enableIcon(item),
                    report: "116",
                    params: { id: item.id },
                    "auto-small": "",
                    "tool-tip": "Resultado em Excel",
                    "show-message": ""
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("sys-dynamic-allocation-register", {
        attrs: {
          icon: _vm.$route.meta.icon,
          model: _vm.model,
          show: _vm.showForm
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("sys-dynamic-allocation-generate", {
        attrs: { model: _vm.modelGenerate, show: _vm.showFormGenerate },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showFormGenerate = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text: _vm.getTextSync(),
          disabled: _vm.synchronizing,
          show: _vm.showSync
        },
        on: {
          "update:show": function($event) {
            _vm.showSync = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "green",
                      loading: _vm.synchronizing,
                      disabled: _vm.synchronizing
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.sync()
                      }
                    }
                  },
                  [
                    _vm._v("Sincronizar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-sync")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }